<template>
  <div class="choose-date-dialog">
    <div class="choose-date-dialog-box">
      <div class="choose-date-dialog-box-header">
        <h2>יצירת אירוע חדש</h2>
        <h4 style="font-weight:400;">{{israeli_format_Date_string(date_data.dateStr)}}</h4>
        <div @click="$emit('close')" class="exit-btn">
           <i class="material-icons">close</i>
        </div>
      </div>
      <div class="choose-date-dialog-box-content">
        <!-- כותרת, תיאור ותאריכים -->
        <div class="choose-date-dialog-box-content-filed">
          <h2>כותרת האירוע</h2>
          <el-input v-model="values.title" placeholder="כותרת האירוע" />
        </div>
        <div class="choose-date-dialog-box-content-filed">
          <h2>תיאור האירוע</h2>
           <el-input
            v-model="values.description "
            :rows="5"
            type="textarea"
            placeholder="כתיבת האירוע..."
          />
        </div>
        <div class="choose-date-dialog-box-content-filed">
          <h2>רשימת מורשים לצפייה</h2>
          <div class="tags">
            <div @click="handle_mark_all" :data-all-marked="all_branched_marked" class="tag everyone">הכל</div>
            <template v-for="branche in branches" :key="branche.branche_num">
              <div @click="handle_mark_branche(branche)" :data-tag="branche.marked" class="tag">{{branche.name}}</div>
            </template>
          </div>
        </div>
        <div class="choose-date-dialog-box-content-filed row">
          <label>
            מספר ימי תזכורת
            <el-input-number style="display:block; width:150px;" v-model="values.days_alarm" :min="1" :max="14"  />
          </label>
          <label>
            מספר העלאת תמונות
            <el-input-number style="display:block; width:150px;" v-model="values.images_amount" :min="1" :max="30"  />
          </label>
        </div>
        <div class="choose-date-dialog-box-content-filed">
          <button v-if="!is_pending" @click="handle_submit" class="submit-btn">יצירת אירוע</button>
          <button v-else disabled  class="submit-btn">אנא המתן...</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed,ref} from 'vue'
import {israeli_format_Date_string} from '../../../../Methods/Dates'
import {slide_pop_error} from '../../../../Methods/Msgs'
import {user} from '../../../../Methods/Utils'

export default {
    emits:['close','submit_new_event'],
    props:['date_data','branches'],
    components:{
      
    },
    setup(props,{emit}){

      const branches = ref(JSON.parse(JSON.stringify(props.branches)))
      const selected_branches = ref([])
      const is_pending = ref(false)

      const values = ref({
        title:'',
        description:'',
        backgroundColor: 'blue',
        days_alarm:7,
        images_amount:1
      })


      const handle_mark_branche = (branche) => {
        branche.marked = !branche.marked
        console.log(branche);
      }

      const handle_mark_all = () => {
        if(all_branched_marked.value){
          branches.value.forEach(b => b.marked = false)
        }else{
          branches.value.forEach(b => b.marked = true)
        }
      }

      const all_branched_marked = ref(computed(() => {
        return branches.value.every(({marked}) => marked)
      }))
      
      const handle_submit = () => {
        try{
          if(!values.value.title) throw new Error('עליך להזין כותרת!')
          if(!values.value.description) throw new Error('עליך להזין תיאור!')
          if(!branches.value.some(({marked}) => marked)) throw new Error('עליך לסמן מי יורשה לצפייה!')
          is_pending.value = true
          const authorized = branches.value
          emit('submit_new_event',{
            ...values.value,
            date_object:props.date_data.date,
            start:props.date_data.dateStr,
            end:props.date_data.dateStr,
            user_uid:user.value.uid,
            approved:[],
            media:[],
            authorized
          })
        }catch(err){
          is_pending.value = false
          slide_pop_error(err.message)
        }
      }

      return{
          israeli_format_Date_string,
          handle_submit,
          values,
          user,
          is_pending,
          selected_branches,
          handle_mark_branche,
          branches,
          all_branched_marked,
          handle_mark_all,
      }
    }
}
</script>

<style scoped>

  .choose-date-dialog{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    overflow-y: auto;
  }
  .choose-date-dialog-box{
    width: 100%;
    max-width: 500px;
    min-height: 350px;
    background: whitesmoke;
    border-radius: 10px;
  }
  .choose-date-dialog-box-header{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom:1px solid lightgray;
  }
  .exit-btn{
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: var(--blue);
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
  }
  .choose-date-dialog-box-content{
    width: 100%;
    height: calc(100% - 50px);
  }
  .choose-date-dialog-box-content-filed{
    width: 100%;
    height: fit-content;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
  .choose-date-dialog-box-content-filed.row{
    flex-direction: row;
    justify-content: space-evenly;
  }
  .submit-btn{
    border: 0;
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
    width: 50%;
    height: 50px;
    outline: none;
    background: var(--blue); 
    align-self: center;
  }
  [type='textarea']{
    white-space: pre-wrap;
  }

  .tags{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .tag{
    padding: 2px 5px;
    background: lightgray;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: #333;
    user-select: none;
    cursor: pointer;
  }
  .tag.everyone{
    padding: 4px 10px;
    font-weight: 400;
  }
  [data-tag=true]{
    background: var(--blue);
    color: #fff;
  }
  [data-all-marked=true]{
    background: var(--success);
    color: #fff;
  }
</style>